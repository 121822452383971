import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  findColor,
  shirtSizes,
  shirtSizesSeparatedByPipes,
  colorOptionsWithPipes,
} from "../helpers/printful-shirt-helper"
import { seoKeyWords } from "../helpers/seo"

const ProductDetails = props => {
  const currentProduct = props.data.allPrintfulProduct.edges.find(
    p =>
      p.node.name.replace(/\s/g, "") === props.path.substr(1).replace(/\s/g, "")
  )
  // const mockupFile = currentProduct && {
  //   shirtName: "mockup",
  //   mainImage: currentProduct.node.sync_variants[0].files[0].preview_url,
  //   color: "",
  // }

  const initialMainShirt = currentProduct && {
    image: currentProduct.node.sync_variants[0].files[1].preview_url,
    color: findColor(currentProduct.node.sync_variants[0].name),
  }

  const currentProductFiles =
    currentProduct &&
    currentProduct.node.sync_variants.reduce((result, variant) => {
      return result.some(
        v =>
          v.variantName.substring(0, v.variantName.indexOf("/")) ===
          variant.name.substring(0, v.variantName.indexOf("/"))
      )
        ? [...result]
        : [
            ...result,
            {
              variantName: variant.name,
              color: findColor(variant.name),
              variantFile: variant.files[1],
            },
          ]
    }, [])

  const [selectedShirt, setSelectedShirt] = React.useState({
    shirtName: "",
    mainImage: initialMainShirt && initialMainShirt.image,
    color: initialMainShirt && initialMainShirt.color,
  })
  const [selectedSize, setSelectedSize] = React.useState("S")
  const [selectedColor, setSelectedColor] = React.useState(
    currentProduct && currentProduct.node.colors[0]
  )

  const handleSizeSelect = e => {
    setSelectedSize(e.target.value)
  }

  const handleColorSelect = e => {
    const color = e.target.value
    const selectedProductFile = currentProductFiles.find(f => f.color === color)
    const selectedShirt = {
      mainImage: selectedProductFile.variantFile.preview_url,
      color,
      name: selectedProductFile.variantName,
    }
    setSelectedColor(color)
    setSelectedShirt(selectedShirt)
  }

  // const selectedVariant = () => {
  //   if (!currentProduct) {
  //     return
  //   }

  //   return currentProduct.node.sync_variants.find(
  //     v => v.name.includes(selectedColor) && v.name.includes(selectedSize)
  //   )
  // }

  // const metadata = JSON.stringify({
  //   external_id: selectedVariant() && selectedVariant().external_id,
  //   variant_id: selectedVariant() && selectedVariant().variant_id,
  // })
  return (
    <Layout printfulProduct={props.pageContext.printfulProduct}>
      <SEO
        title={props.pageContext.printfulProduct.name}
        keywords={seoKeyWords}
      />
      <div className="container details-page">
        <div className="product-details">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="Product-Screenshot">
                <img
                  src={
                    selectedShirt.mainImage ||
                    props.pageContext.printfulProduct.thumbnail_url
                  }
                  alt="mainImage"
                />
                {currentProductFiles &&
                  currentProductFiles.map((file, i) => (
                    /*eslint-disable */
                    <img
                      onClick={() =>
                        setSelectedShirt({
                          name: file.variantName,
                          mainImage: file.variantFile.preview_url,
                          color: file.color,
                        })
                      }
                      key={`tab-${file.variantFile.id}`}
                      src={file.variantFile.preview_url}
                      alt={file.variantName.substring(
                        0,
                        file.variantName.indexOf("/")
                      )}
                      style={{ height: 80, width: 80 }}
                    />
                  ))}
                {/* this image below causes bugs when the text or design is light */}
                {/* <img
                  onClick={() => setSelectedShirt(mockupFile)}
                  src={mockupFile && mockupFile.mainImage}
                  style={{ height: 80, width: "auto" }}
                  alt={mockupFile && mockupFile.shirtName}
                /> */}
                {/* eslint-enable */}
              </div>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <h1>{props.pageContext.printfulProduct.name}</h1>
              <h2 className="price">Price: $19</h2>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="sizeSelect">Color</label>
                  {/* eslint-disable */}
                  <select
                    className="form-control"
                    id="colorSelect"
                    onChange={handleColorSelect}
                  >
                    {currentProduct &&
                      currentProduct.node.colors.map(color => (
                        <option key={color}>{color}</option>
                      ))}
                  </select>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="sizeSelect">Size</label>
                  <select
                    className="form-control"
                    id="sizeSelect"
                    onChange={handleSizeSelect}
                  >
                    {shirtSizes.map(size => (
                      <option key={size}>{size}</option>
                    ))}
                    {/* eslint-enable */}
                  </select>
                </div>
              </div>
              <div>
                <button
                  className="Product snipcart-add-item wide-snipcart-button"
                  data-item-id={props.pageContext.printfulProduct.id}
                  data-item-price={19}
                  data-item-image={
                    !props.pageContext.printfulProduct.thumbnail_url
                      ? ""
                      : props.pageContext.printfulProduct.thumbnail_url
                  }
                  data-item-name={props.pageContext.printfulProduct.name}
                  data-item-custom1-name="Size"
                  data-item-custom1-options={shirtSizesSeparatedByPipes}
                  data-item-custom1-value={selectedSize}
                  data-item-custom2-name="Color"
                  data-item-custom2-options={
                    currentProduct &&
                    colorOptionsWithPipes(currentProduct.node.colors)
                  }
                  data-item-custom2-value={selectedColor}
                  data-item-url={"/store"}
                  data-item-metadata='{"test": "100"}'
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductDetails

export const query = graphql`
  query ProductDetailsQuery($slug: String!) {
    allPrintfulProduct {
      edges {
        node {
          id
          productId
          externalId
          name
          variants
          synced
          thumbnail_url
          colors
          sizes
          sync_variants {
            id
            external_id
            currency
            files {
              created
              dpi
              filename
              hash
              height
              id
              mime_type
              preview_url
              size
              status
              thumbnail_url
              type
              visible
              width
            }
            name
            options {
              id
            }
            product {
              variant_id
              product_id
              image
              name
            }
            retail_price
            sync_product_id
            synced
            variant_id
          }
        }
      }
    }
    contentfulProduct(slug: { eq: $slug }) {
      id
      name
      slug
      image {
        fixed(width: 1120, height: 500) {
          width
          height
          src
          srcSet
        }
      }
      price
      details {
        childMarkdownRemark {
          html
        }
      }
      productMorePhotos {
        id
        fixed(width: 1120, height: 600) {
          src
        }
      }
      rating
    }
  }
`
